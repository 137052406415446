/* Container for the Privacy Policy */
.privacy-policy-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 48px 32px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.06);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  line-height: 1.8;
  color: #222;
}

/* Headings Styling */
.privacy-policy-container h1 {
  font-size: 2.75rem;
  margin-bottom: 30px;
  color: #1d1d1d;
  font-weight: 700;
  text-align: left;
}

.privacy-policy-container h2 {
  font-size: 1.75rem;
  margin-top: 40px;
  margin-bottom: 16px;
  color: #003366;
  border-bottom: 2px solid #003366;
  padding-bottom: 6px;
}

.privacy-policy-container h3 {
  font-size: 1.4rem;
  margin-top: 24px;
  margin-bottom: 12px;
  color: #0056b3;
}

/* Paragraphs Styling */
.privacy-policy-container p {
  font-size: 1.05rem;
  margin-bottom: 20px;
  color: #444;
}

/* Lists Styling */
.privacy-policy-container ul {
  padding-left: 24px;
  margin-bottom: 24px;
}

.privacy-policy-container ul li {
  margin-bottom: 10px;
  font-size: 1.05rem;
  list-style-type: disc;
}

/* Link Styling */
.privacy-policy-container a {
  color: #0056b3;
  text-decoration: none;
  border-bottom: 1px dotted #0056b3;
}

.privacy-policy-container a:hover {
  color: #003366;
  border-bottom: 1px solid #003366;
}

/* Emphasis Text */
.privacy-policy-container p strong {
  color: #1d1d1d;
  font-weight: 600;
}

/* Responsive Layout for Tablets */
@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 32px 20px;
  }

  .privacy-policy-container h1 {
    font-size: 2rem;
  }

  .privacy-policy-container h2 {
    font-size: 1.5rem;
  }

  .privacy-policy-container h3 {
    font-size: 1.25rem;
  }

  .privacy-policy-container p,
  .privacy-policy-container ul li {
    font-size: 1rem;
  }
}

/* Responsive Layout for Mobile Phones */
@media (max-width: 480px) {
  .privacy-policy-container {
    padding: 24px 15px;
  }

  .privacy-policy-container h1 {
    font-size: 1.75rem;
  }

  .privacy-policy-container h2 {
    font-size: 1.3rem;
  }

  .privacy-policy-container h3 {
    font-size: 1.1rem;
  }

  .privacy-policy-container p,
  .privacy-policy-container ul li {
    font-size: 0.95rem;
  }

  .privacy-policy-container ul {
    padding-left: 18px;
  }
}